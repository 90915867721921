// src/components/common/UserTopBar.tsx

import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import {
  TopbarWrapper,
  Logo,
  NavMenu,
  NavItem,
  SignInButton,
  HamburgerMenu,
  DropdownMenu,
  DropdownItem,
  DropdownWrapper,
} from '../../styles/UserTopbarStyles';
import { FaBars } from 'react-icons/fa';
import { getAccessToken, logout, getUserProfile } from '../../services/userService';
import LoadingSpinner from './LoadingSpinner';

const UserTopBar: React.FC = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For mobile menu
  const [showDesktopDropdown, setShowDesktopDropdown] = useState(false); // For desktop dropdown
  const [userFirstName, setUserFirstName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Set initial state to false
  const desktopDropdownRef = useRef<HTMLDivElement | null>(null);
  const mobileDropdownRef = useRef<HTMLDivElement | null>(null);

  const logoImageUrl = 'https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg';

  const toggleDesktopDropdown = () => {
    setShowDesktopDropdown((prev) => !prev);
  };

  const toggleMobileDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Close dropdowns if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        desktopDropdownRef.current &&
        !desktopDropdownRef.current.contains(event.target as Node)
      ) {
        setShowDesktopDropdown(false);
      }
      if (
        mobileDropdownRef.current &&
        !mobileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch user data if logged in
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true); // Start loading
      const token = getAccessToken();
      if (token) {
        try {
          const userData = await getUserProfile();
          setUserFirstName(userData.first_name);
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Handle token expiration or invalid token
          await logout();
          setUserFirstName(null);
        } finally {
          setIsLoading(false); // End loading
        }
      } else {
        setIsLoading(false); // End loading if no token
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    setIsLoading(true); // Start loading
    try {
      await logout();
      setUserFirstName(null);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setIsLoading(false); // End loading when fetching is done
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <TopbarWrapper>
        {/* Logo */}
        <Logo to="/">
          <img src={logoImageUrl} alt="Logo" />
        </Logo>

        {/* Navigation Menu for Desktop */}
        <NavMenu>
          <NavItem>
            <NavLink to="/agents">Agents</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/blogs">Blogs</NavLink>
          </NavItem>
          {userFirstName ? (
            <NavItem ref={desktopDropdownRef}>
              <SignInButton onClick={toggleDesktopDropdown}>
                Hi {userFirstName}
              </SignInButton>
              {showDesktopDropdown && (
                <DropdownWrapper isOpen={showDesktopDropdown}>
                  <DropdownMenu>
                    {isLoading ? (
                      <DropdownItem>
                        <LoadingSpinner />
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        onClick={!isLoading ? handleLogout : undefined}
                        disabled={isLoading}
                      >
                        Logout
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </DropdownWrapper>
              )}
            </NavItem>
          ) : (
            <NavItem>
              <SignInButton onClick={() => navigate('/login')}>Login</SignInButton>
            </NavItem>
          )}
        </NavMenu>

        {/* Hamburger Menu for Small Screens */}
        <HamburgerMenu onClick={toggleMobileDropdown}>
          <FaBars />
        </HamburgerMenu>

        {/* Dropdown Menu for Small Screens */}
        {isDropdownOpen && (
          <DropdownWrapper ref={mobileDropdownRef} isOpen={isDropdownOpen}>
            <DropdownMenu>
              <DropdownItem>
                <Link to="/agents">Agents</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/blogs">Blogs</Link>
              </DropdownItem>
              {userFirstName ? (
                <>
                  <DropdownItem>
                    <span>Hi {userFirstName}</span>
                  </DropdownItem>
                  {isLoading ? (
                    <DropdownItem>
                      <LoadingSpinner />
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={!isLoading ? handleLogout : undefined}
                      disabled={isLoading}
                    >
                      Logout
                    </DropdownItem>
                  )}
                </>
              ) : (
                <DropdownItem>
                  <SignInButton onClick={() => navigate('/login')}>Login</SignInButton>
                </DropdownItem>
              )}
            </DropdownMenu>
          </DropdownWrapper>
        )}
      </TopbarWrapper>
    </>
  );
};

export default UserTopBar;
