// src/services/api.ts
import axios from 'axios';
import { getNewAccessToken } from './agentService';

// Create an Axios instance with base configuration
const api = axios.create({
  baseURL: 'https://backend.timeaz.com/api',
  // baseURL: 'http://127.0.0.1:8000/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to automatically refresh token on 401 Unauthorized
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await getNewAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        return api(originalRequest);
      } catch (err) {
        // Redirect to agent login if token refresh fails
        window.location.href = '/agent-login';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default api;