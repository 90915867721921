// styles/BlogPostCardStyles.ts

import styled from 'styled-components';

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.boxShadow};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.small} ${({ theme }) =>
  theme.borderRadius.small} 0 0;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ theme }) => theme.spacing.m2} ${({ theme }) => theme.spacing.m1};
`;

export const Excerpt = styled.p`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin: ${({ theme }) => theme.spacing.m2} ${({ theme }) => theme.spacing.m1};
  line-height: 1.6;
`;
