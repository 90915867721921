// src/styles/globalStyles.ts
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Roboto;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.background};
    line-height: 1.6;
  }

  h1, h2, h3 {
    color: ${({ theme }) => theme.heading};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.link};
  }
`;
