import React from 'react';
import { BlogPost } from '../../services/blogService';
import { Link } from 'react-router-dom';
import {
  CardWrapper,
  Thumbnail,
  Title,
  Excerpt,
} from '../../styles/BlogPostCardStyles';

interface BlogPostCardProps {
  post: BlogPost;
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ post }) => (
  <CardWrapper>
    <Link to={`/blogs/${post.slug}`}>
      <Thumbnail src={post.featured_image} alt={post.title} />
      <Title>{post.title}</Title>
      <Excerpt>{post.content.slice(0, 80)}...</Excerpt>
    </Link>
  </CardWrapper>
);

export default BlogPostCard;
