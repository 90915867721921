import React, { useState, useMemo } from 'react';
import {
  GridWrapper,
  LargePhotoContainer,
  SmallPhotoGrid,
  SmallPhotoContainer,
  ViewAllButton,
  ModalWrapper,
  ModalContent,
  CloseButton,
} from '../../styles/PhotoGridStyles';
import { Photo } from '../../services/propertyService';

interface PhotoGridProps {
  photos: Photo[]; // Array of photos with rank and URLs
}

const PhotoGrid: React.FC<PhotoGridProps> = ({ photos }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Sort the photos by rank to determine layout
  const sortedPhotos = useMemo(() => {
    return photos.slice().sort((a, b) => a.rank - b.rank); // Non-destructive sort
  }, [photos]);

  // Get the large photo (rank 1) and small photos (ranks 2-5)
  const largePhoto = sortedPhotos.find((photo) => photo.rank === 1);
  const smallPhotos = sortedPhotos.filter((photo) => photo.rank >= 2 && photo.rank <= 5);

  // Open and close modal handlers
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div>
      <GridWrapper>
        {/* Large Photo */}
        <LargePhotoContainer onClick={openModal}>
          {largePhoto && <img src={largePhoto.image_url} alt="Main property photo" />}
        </LargePhotoContainer>

        {/* Small Photos Grid */}
        <SmallPhotoGrid>
          {smallPhotos.map((photo) => (
            <SmallPhotoContainer key={photo.id} onClick={openModal}>
              <img src={photo.image_url} alt={`Small photo with rank ${photo.rank}`} />
            </SmallPhotoContainer>
          ))}

          {/* View All Button */}
          <ViewAllButton onClick={openModal}>
            Show all {photos.length} photos
          </ViewAllButton>
        </SmallPhotoGrid>
      </GridWrapper>

      {/* Modal to Display All Photos */}
      {isModalOpen && (
        <ModalWrapper onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
            {photos.map((photo) => (
              <img key={photo.id} src={photo.image_url} alt={`Photo with rank ${photo.rank}`} />
            ))}
          </ModalContent>
        </ModalWrapper>
      )}
    </div>
  );
};

export default PhotoGrid;
