import React, { useState, useEffect } from 'react';
import { agentLogin } from '../../services/agentService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, LinkStyled } from '../../styles/common'; // Reusing common styles
import { LoginWrapper, LoginBox, Logo } from '../../styles/AgentLoginPageStyles';

interface LocationState {
  from?: string;
  step?: number;
}

const AgentLoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const localStorageKey = 'propertyCreationData';

  const logoImageUrl = "https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg";  

  // Type assertion to specify the type of location state
  const state = location.state as LocationState;

  // Check if the user is already authenticated
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const savedFormData = localStorage.getItem(localStorageKey);
      if (savedFormData) {
        const { step: savedStep } = JSON.parse(savedFormData);
        const step = state?.step || savedStep || 1;
        navigate(`/agent/add-property?step=${step}`, { replace: true });
      } else {
        const redirectTo = state?.from || '/agent-dashboard';
        navigate(redirectTo, { replace: true });
      }
    }
  }, [navigate, state]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await agentLogin({ email, password });

      // Check if there's saved form data
      const savedFormData = localStorage.getItem(localStorageKey);

      if (savedFormData) {
        const { step: savedStep } = JSON.parse(savedFormData);
        const step = state?.step || savedStep || 1;
        navigate(`/agent/add-property?step=${step}`);
      } else {
        // Otherwise, navigate to the dashboard or the saved path
        const redirectAfterLogin = state?.from || '/agent-dashboard';
        navigate(redirectAfterLogin);
      }
    } catch (err) {
      setError('Invalid login credentials. Please try again.');
    }
  };

  return (
    <LoginWrapper>
      <LoginBox>
        <Logo>
          <img src={logoImageUrl} alt="Logo" />
        </Logo>
        <Form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <Button type="submit">Login</Button>
        </Form>
        <div style={{ marginTop: '20px' }}>
          <LinkStyled to="/agent-signup">Sign up</LinkStyled> {/* Sign up link */}
          <LinkStyled to="/auth/forgot-password">Forgot Password?</LinkStyled> {/* Forgot password link */}
        </div>
      </LoginBox>
    </LoginWrapper>
  );
};

export default AgentLoginPage;
