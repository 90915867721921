// src/styles/UserTopbarStyles.ts

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TopbarWrapper = styled.div`
  position: fixed; /* Fix the topbar at the top */
  top: 0;
  left: 0;
  width: 100%; /* Ensure the topbar spans the full width */
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.p3};
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  
  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p2};
    flex-direction: column; /* Stack items vertically on extra small screens */
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p3};
    flex-direction: row;
  `}

  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p3};
  `}
`;


// Updated Logo component to use an image instead of text
export const Logo = styled(Link)`
  margin-left: ${({ theme }) => theme.spacing.m3};

  img {
    height: 50px; /* Adjust the height of the logo */
    width: auto; /* Maintain aspect ratio */
  }

  &:hover {
    img {
      opacity: 0.8; /* Slight opacity change on hover */
    }
  }

  ${({ theme }) => theme.media.medium`
    margin-left: ${theme.spacing.m2};
    img {
      height: 40px; /* Adjust logo size on medium screens */
    }
  `}

  ${({ theme }) => theme.media.small`
    margin-left: ${theme.spacing.m2};
    img {
      height: 35px; /* Adjust logo size on small screens */
    }
  `}
`;

// Navigation menu and items
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.m3};

  ${({ theme }) => theme.media.medium`
    margin-right: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.small`
    display: none; /* Hide NavMenu on small screens */
  `}
`;

// Navigation items in the top bar
export const NavItem = styled.div`
  position: relative; /* For dropdown positioning */
  margin-left: ${({ theme }) => theme.spacing.m3};

  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.typography.body.fontSize};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme }) => theme.media.medium`
    margin-left: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.small`
    margin-left: ${theme.spacing.m1};
    margin-bottom: ${theme.spacing.m2}; /* Add space between menu items */
  `}

  ${({ theme }) => theme.media.extraSmall`
    margin-left: ${theme.spacing.m1};
    margin-bottom: ${theme.spacing.m2};
  `}
`;

// Sign-in button
export const SignInButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => `${theme.spacing.p1} ${theme.spacing.p3}`};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
  }

  ${({ theme }) => theme.media.medium`
    width: 100%;
    text-align: center;
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
    width: auto;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1};
    font-size: ${theme.typography.small.fontSize};
  `}
`;

interface DropdownWrapperProps {
  isOpen: boolean;
  mobile?: boolean;
}

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  position: ${({ mobile }) => (mobile ? 'fixed' : 'absolute')};
  top: ${({ mobile }) => (mobile ? '60px' : '100%')};
  right: 0;
  width: ${({ mobile }) => (mobile ? '100%' : 'auto')};
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme, mobile }) =>
    mobile ? '0' : theme.borderRadius.small};
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: 1000;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: ${({ theme }) => theme.spacing.p1} 0;

  ${({ mobile }) =>
    mobile &&
    `
    height: 100vh; /* Full height for mobile dropdown */
  `}
`;

// Dropdown menu structure
export const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// Dropdown items
export const DropdownItem = styled.li<{ disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing.p1} ${({ theme }) => theme.spacing.p3};
  cursor: pointer;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.6;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: white;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1};
    a {
      color: ${theme.colors.text};
    }
  `}
`;

// Hamburger menu for small screens
export const HamburgerMenu = styled.div`
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.p1};

  ${({ theme }) => theme.media.small`
    display: block; /* Show on small screens */
  `}
`;

