// styles/BlogListPageStyles.ts
import styled from 'styled-components';

export const BlogListContainer = styled.div`
  padding-top: 80px; /* Adjust this value to match the UserTopBar's height */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.m2};
`;
