// BlogListPage.tsx
import React, { useEffect, useState } from 'react';
import BlogPostCard from '../../components/common/BlogPostCard';
import { BlogPost, getBlogPosts } from '../../services/blogService';
import UserTopBar from '../../components/common/UserTopBar';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { BlogListContainer } from '../../styles/BlogListPageStyles';

const BlogListPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const data = await getBlogPosts();
        setPosts(data); // Now it properly receives the posts array
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setIsLoading(false); // End loading when fetching is done
      }
    };
    fetchPosts();
  }, []);

  return (
    <>
      <UserTopBar />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <BlogListContainer>
          {posts.length > 0 ? (
            posts.map((post) => <BlogPostCard key={post.id} post={post} />)
          ) : (
            <p>No blog posts found.</p>
          )}
        </BlogListContainer>
      )}
    </>
  );
};

export default BlogListPage;
