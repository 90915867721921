// src/pages/auth/LoginPage.tsx

import React, { useState, useEffect } from 'react';
import { login } from '../../services/userService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, LinkStyled } from '../../styles/common';
import { LoginWrapper, LoginBox, Logo, AdditionalLinks } from '../../styles/LoginPageStyles'; // Import AdditionalLinks
import GoogleLoginInitiate from '../../components/common/GoogleLoginInitiate';

// Define a type for the state that comes from the `useLocation`
interface LocationState {
  from?: string;
}

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const logoImageUrl = 'https://timeaz.blob.core.windows.net/media/LOGO_TIMEAZ.svg';

  // Type assertion to specify the type of location state
  const state = location.state as LocationState;

  // Check if the user is already authenticated
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const redirectTo = state?.from || '/';
      navigate(redirectTo, { replace: true });
    }
  }, [navigate, state]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login({ username, password });

      // Check if there's a redirect URL saved in the location state
      const redirectAfterLogin = state?.from;

      if (redirectAfterLogin) {
        // Redirect to the saved path after login
        navigate(redirectAfterLogin, { replace: true });
      } else {
        // If no redirect URL, default to home or dashboard
        navigate('/');
      }
    } catch (err) {
      setError('Invalid login credentials. Please try again.');
    }
  };

  return (
    <LoginWrapper>
      <LoginBox>
        <Logo>
          <img src={logoImageUrl} alt="Logo" />
        </Logo>

        <Form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <Button type="submit">Login</Button>
        </Form>

        <GoogleLoginInitiate />

        <AdditionalLinks>
          <p>
            <strong>Are you an agent?</strong> <LinkStyled to="/agent-login">Login here</LinkStyled>
          </p>
        </AdditionalLinks>

        <AdditionalLinks>
          <LinkStyled to="/signup">Sign up</LinkStyled>
          <LinkStyled to="/forgot-password">Forgot Password?</LinkStyled>
        </AdditionalLinks>
      </LoginBox>
    </LoginWrapper>
  );
};

export default LoginPage;
