import styled from 'styled-components';

// Main container for the homepage
export const HomePageContainer = styled.div`
  max-width: 100%; /* Allow the container to use the full width */
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.p4};
  background-color: ${({ theme }) => theme.colors.background};
  box-sizing: border-box; /* Ensure padding is considered within width */
  padding-top: 120px; 

  ${({ theme }) => theme.media.extraLarge`
    padding: ${theme.spacing.p3};
  `}

  ${({ theme }) => theme.media.large`
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p1};
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p0};
  `}
`;

// Search section container with responsive layout
export const SearchSection = styled.section`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.m5} 0;

  h1 {
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.spacing.m3};

    ${({ theme }) => theme.media.large`
      font-size: 1.75rem;
    `}

    ${({ theme }) => theme.media.medium`
      font-size: 1.5rem;
    `}

    ${({ theme }) => theme.media.small`
      font-size: 1.3rem;
    `}

    ${({ theme }) => theme.media.extraSmall`
      font-size: 1.2rem;
    `}    
  }

  .filter-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.m3};
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing.m5};

    ${({ theme }) => theme.media.medium`
      gap: ${theme.spacing.m2};
      flex-direction: column; /* Stack filters vertically */
    `}

    ${({ theme }) => theme.media.small`
      gap: ${theme.spacing.m1};
    `}

    ${({ theme }) => theme.media.extraSmall`
      gap: ${theme.spacing.m0};
    `}
  }
`;

// Search button style
export const SearchButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  padding: ${({ theme }) => `${theme.spacing.p2} ${theme.spacing.p3}`};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }

  svg {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
  }

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1};
    font-size: ${theme.typography.small.fontSize};
  `}  
`;

// Properties grid container for responsive layout
export const PropertiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.spacing.m3};
  margin: 0 ${({ theme }) => theme.spacing.m0}; /* Remove horizontal margin */

  ${({ theme }) => theme.media.extraLarge`
    width: calc(100% - ${theme.spacing.p3} * 2); /* Adjust width for extra large screens */
  `}

  ${({ theme }) => theme.media.large`
    width: calc(100% - ${theme.spacing.p2} * 2); /* Adjust width for large screens */
    grid-template-columns: repeat(3, 1fr);
  `}

  ${({ theme }) => theme.media.medium`
    width: calc(100% - ${theme.spacing.p2} * 2);
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.small`
    width: calc(100% - ${theme.spacing.p1} * 2); /* Full width on small screens */
    grid-template-columns: 1fr;
    gap: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 100%;
    gap: ${theme.spacing.m1}; /* Minimal gap on extra small screens */
  `}
`;

// Individual property card with responsive hover effect
export const PropertyCard = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.boxShadow};
  transition: ${({ theme }) => theme.transition};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.p0};
  margin: ${({ theme }) => theme.spacing.m0}; /* Remove default margin */
  position: relative;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  ${({ theme }) => theme.media.extraSmall`
    border-radius: ${theme.borderRadius.small}; /* Smaller border radius on small screens */
  `}
`;

// Property details section for description and information
export const PropertyDetails = styled.div`
  margin: ${({ theme }) => theme.spacing.m2};

  .property-price {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
  }

  .property-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.m1};
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    margin: ${({ theme }) => `${theme.spacing.m1} 0`};
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1.4;
  }

  ${({ theme }) => theme.media.small`
    .property-price {
      font-size: ${theme.typography.body.fontSize};
    }

    .property-info {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      font-size: ${theme.typography.small.fontSize};
    }
  `}
`;

// Agent details container with consistent spacing and styling
export const AgentDetails = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.m2};
  position: relative;

  .agent-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.spacing.m2};
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .verified-icon {
      position: absolute;
      bottom: -2px; /* Adjust to align at the bottom */
      right: -2px; /* Adjust to align at the right */
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      padding: 2px;
      width: 16px;
      height: 16px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  .agent-info {
    display: flex;
    align-items: center;

    .agent-name {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text};
      font-size: ${({ theme }) => theme.typography.body.fontSize};
    }

    .divider {
      margin: 0 ${({ theme }) => theme.spacing.m1}; /* Spacing on both sides */
      color: ${({ theme }) => theme.colors.border};
    }

    .agent-rating {
      font-size: ${({ theme }) => theme.typography.small.fontSize};
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.background};
      padding: ${({ theme }) => `${theme.spacing.p1} ${theme.spacing.p2}`};
      border-radius: ${({ theme }) => theme.borderRadius.large};
      font-weight: bold;
    }

    .review-count {
      font-size: ${({ theme }) => theme.typography.small.fontSize};
      color: ${({ theme }) => theme.colors.textSecondary};
    }

    .agent-sales {
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }
`;

// Recommended section heading for property recommendations
export const RecommendedHeading = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
  color: ${({ theme }) => theme.typography.h2.color};
  text-align: left;
  margin: ${({ theme }) => `${theme.spacing.m2} 0`};
`;


// Blog section container
export const BlogSection = styled.section`
  width: 100%;
  max-width: 1200px;
  margin-top: ${({ theme }) => theme.spacing.m5};
`;

// Blog heading style
export const BlogHeading = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.m3};

  ${({ theme }) => theme.media.small`
    font-size: ${theme.typography.h3.fontSize};
  `}
`;

// Grid layout for blog posts
export const BlogPostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.spacing.m5};
  justify-content: center;

  ${({ theme }) => theme.media.large`
    grid-template-columns: repeat(2, 1fr); /* Adjust for large screens */
  `}

  ${({ theme }) => theme.media.medium`
    grid-template-columns: 1fr; /* Stack posts on smaller screens */
  `}
`;