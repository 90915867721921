import React from 'react';
import styled from 'styled-components';

// Styles for the contact section
const ContactSectionContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin-top: 3rem;

  ${({ theme }) => theme.media.small`
    padding: 1.5rem;
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: 1rem;
  `}
`;

const ContactTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.m2};
`;

const ContactInfo = styled.p`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContactSection: React.FC = () => {
  return (
    <ContactSectionContainer>
      <ContactTitle>Contact Us</ContactTitle>
      <ContactInfo>
        📧 Email: <a href="mailto:moein.kazemimk2016@gmail.com">moein.kazemimk2016@gmail.com</a>
      </ContactInfo>
      <ContactInfo>
        📞 Phone: <a href="tel:+1234567890">+1 (604) 518-5357</a>
      </ContactInfo>
    </ContactSectionContainer>
  );
};

export default ContactSection;
