import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  FaSwimmingPool, FaBuilding, FaHandsWash, FaChild, FaDumbbell, 
  FaSoap, FaUserTie, FaTree, FaHandshake, FaDoorOpen, FaLock, 
  FaBook, FaBusAlt, FaUsers, FaBath, FaEye, FaCalendarAlt, FaHome, FaRulerCombined, FaBed,
  FaMapMarkerAlt, FaCheckCircle
} from 'react-icons/fa';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import UserTopBar from '../../components/common/UserTopBar';
import {
  getPropertyById,
  PropertyDetail,
  getAgentPublicProfile,
  sendMessageToAgent,
  AgentProfile,
  getCities,
  getHomeViews,
  getPropertyStatuses,
  getHomeTypes,
  getAmenities,
  City,
  HomeView,
  PropertyStatus,
  HomeType,
  Amenity
} from '../../services/propertyService';
import PhotoGrid from '../../components/property/PhotoGrid';
import { PropertyDetailsContainer, ContactForm, MainContent, ContactFormContainer,
  MainContentWrapper, PhotoSection, MapSection
 } from '../../styles/PropertyDetailsStyles';
import { PriceSection, AboutSection, AmenitiesSection, StyledButton, SpecialSection } from '../../styles/PropertyReviewStyles';
import { AgentDetails } from '../../styles/HomePageStyles';
import { getAgentReviewSummary, AgentReviewSummary } from '../../services/agentService';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import 'font-awesome/css/font-awesome.min.css';

const awesomeIcon = L.AwesomeMarkers.icon({
  icon: 'home',          // The icon name (e.g., 'home')
  prefix: 'fa',          // The font prefix ('fa' for FontAwesome)
  markerColor: 'red',   // Marker color
  iconColor: 'white',    // Icon color inside the marker
});

// Define a type for the possible amenities
type AmenityType =
  | 'swimming pool'
  | 'parking'
  | 'laundry'
  | 'kids ground play'
  | 'indoor gym'
  | 'cleaning services'
  | 'on-site management'
  | 'outdoor recreational areas'
  | 'landscape'
  | 'conference rooms'
  | 'event rooms'
  | 'security guard'
  | 'study room'
  | 'shuttle service'
  | 'clubhouse'
  | 'turkish bath';

const PropertyDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();  // Add useNavigate hook
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [property, setProperty] = useState<PropertyDetail | null>(null);
  const [agentProfile, setAgentProfile] = useState<AgentProfile | null>(null);
  const [cities, setCities] = useState<City[]>([]); // State to store cities
  const [homeTypes, setHomeTypes] = useState<HomeType[]>([]); // State to store home types
  const [homeViews, setHomeViews] = useState<HomeView[]>([]); // State to store home views
  const [propertyStatuses, setPropertyStatuses] = useState<PropertyStatus[]>([]); // State to store property statuses
  const [amenities, setAmenities] = useState<Amenity[]>([]); // State to store amenities
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [agentReview, setAgentReview] = useState<AgentReviewSummary | null>(null);
  const [message, setMessage] = useState({
    full_name: '',
    contact_info: '',
    message: '',
  });

  const amenityIcons: Record<AmenityType, JSX.Element> = {
    'swimming pool': <FaSwimmingPool />,
    'parking': <FaBuilding />,
    'laundry': <FaHandsWash />,
    'kids ground play': <FaChild />,
    'indoor gym': <FaDumbbell />,
    'cleaning services': <FaSoap />,
    'on-site management': <FaUserTie />,
    'outdoor recreational areas': <FaTree />,
    'landscape': <FaTree />,
    'conference rooms': <FaHandshake />,
    'event rooms': <FaDoorOpen />,
    'security guard': <FaLock />,
    'study room': <FaBook />,
    'shuttle service': <FaBusAlt />,
    'clubhouse': <FaUsers />,
    'turkish bath': <FaBath />,
  };

  const renderAmenityIcon = (amenityName: string) => {
    const lowerCaseAmenity = amenityName.toLowerCase() as AmenityType;
    return amenityIcons[lowerCaseAmenity] || <FaHome />; // FaHome as fallback
  };

  useEffect(() => {
    async function fetchPropertyDetails() {
      try {
        setIsLoading(true);
        const propertyData = await getPropertyById(Number(id));
        setProperty(propertyData);

        const agentData = await getAgentPublicProfile(propertyData.agent_id);
        const reviewData = await getAgentReviewSummary(propertyData.agent_id)
        setAgentReview(reviewData)
        setAgentProfile(agentData);

        const fetchedCities = await getCities();
        setCities(fetchedCities); // Save the cities list

        // Fetch home types, views, statuses, and amenities for lookup
        const fetchedHomeTypes = await getHomeTypes();
        const fetchedHomeViews = await getHomeViews();
        const fetchedStatuses = await getPropertyStatuses();
        const fetchedAmenities = await getAmenities();

        setHomeTypes(fetchedHomeTypes);
        setHomeViews(fetchedHomeViews);
        setPropertyStatuses(fetchedStatuses);
        setAmenities(fetchedAmenities);
      } catch (err) {
        setError('Failed to load property or agent details.');
      } finally {
        setIsLoading(false);
      }
    }

    fetchPropertyDetails();
  }, [id]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (property && agentProfile) {
      try {
        const messageData = {
          ...message,
          property: property.id,
          agent: property.agent_id,
        };
        await sendMessageToAgent(messageData);
        alert('Message sent successfully!');
      } catch (err) {
        alert('Failed to send message.');
      }
    }
  };

  // Handle agent click to navigate to the agent's profile page
  const handleAgentClick = (agentId: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent card's click event
    navigate(`/agent-profile/${agentId}`);
  };

  const getCityName = (cityId: number): string => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : 'Unknown City';
  };

  const getHomeTypeName = (typeId: number): string => {
    const homeType = homeTypes.find((type) => type.id === typeId);
    return homeType ? homeType.name : 'Unknown Type';
  };

  const getHomeViewName = (viewId: number): string => {
    const homeView = homeViews.find((view) => view.id === viewId);
    return homeView ? homeView.name : 'Unknown View';
  };

  const getPropertyStatus = (statusId: number): PropertyStatus | undefined => {
    return propertyStatuses.find((status) => status.id === statusId);
  };

  const getAmenityName = (amenityId: number): string => {
    const amenity = amenities.find((amenity) => amenity.id === amenityId);
    return amenity ? amenity.name : 'Unknown Amenity';
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!property) {
    return null;
  }

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(number);
  };

  const status = getPropertyStatus(property.status); // Get status object by ID

  return (
    <>
    <UserTopBar />
    {isLoading && <LoadingSpinner />}
    <PropertyDetailsContainer>
      {/* Photo Section */}
      <PhotoSection>
        <PhotoGrid photos={property.photos} />
      </PhotoSection>
      <MainContentWrapper>
        <MainContent>

          <PriceSection>
            <div className="left-section">
            <h1>{`${property.currency}${formatPrice(property.price)}`}</h1>
              <div className="project-location">
                <h2>{property.project_name} project</h2> {/* Project Name */}
                <p><FaMapMarkerAlt /> {getCityName(property.city)}</p> {/* City Name */}
              </div>
            </div>
            <div className="details">
              <span><FaBed /> {property.bedrooms} beds</span> {/* Bedrooms */}
              <span><FaBath /> {property.bathrooms} baths</span> {/* Bathrooms */}
              <span><FaRulerCombined /> {property.area} sqm</span> {/* Area */}
            </div>
          </PriceSection>

          <AboutSection>
            {/* Property Type */}
            <div>
              <FaHome /> <span>{getHomeTypeName(property.property_type)}</span>
            </div>

            {/* Status with Date */}
            <div className="status">
              <div className="status-name">
                <FaCalendarAlt /> <span>{status?.name}</span> {/* Show status name */}
              </div>
              <span className="status-date">
                {status?.requires_delivery_date && property.delivery_date && `Delivery Date: ${property.delivery_date}`}
                {status?.requires_built_in_date && property.built_in && `Built-in Date: ${property.built_in}`}
              </span>
            </div>

            {/* View */}
            <div>
              <FaEye /> <span>{getHomeViewName(property.view)} view</span>
            </div>

            {/* Floor */}
            <div>
              <FaBuilding /> <span>Floor {property.floor}</span>
            </div>
          </AboutSection>

          {/* Amenities Section */}
          <AmenitiesSection>
            <h3>What this place offers</h3>
            <div className="amenities-grid">
              {property.amenities.slice(0, showAll ? property.amenities.length : 6).map((amenityId, index) => {
                const amenityName = getAmenityName(amenityId); // Get amenity name by ID
                return (
                  <div key={index} className="amenity-item">
                    {renderAmenityIcon(amenityName)} <span>{amenityName}</span>
                  </div>
                );
              })}
            </div>

            {/* Show "Show All" Button if there are more than 6 amenities */}
            {property.amenities.length > 6 && !showAll && (
              <StyledButton onClick={() => setShowAll(true)}>
                Show all {property.amenities.length} amenities
              </StyledButton>
            )}

            {/* Optionally hide the amenities again */}
            {showAll && (
              <StyledButton onClick={() => setShowAll(false)}>
                Show less
              </StyledButton>
            )}
          </AmenitiesSection>

          {/* What's Special Section */}
          <SpecialSection>
            <h3>What's special</h3>
            <p>
              {showFullDescription ? property.description : `${property.description.split(' ').slice(0, 50).join(' ')}...`}
            </p>

            {/* Show "Show more" button if description exceeds threshold */}
            {property.description.split(' ').length > 50 && !showFullDescription && (
              <StyledButton onClick={() => setShowFullDescription(true)}>
                Show more
              </StyledButton>
            )}

            {/* Optionally hide the description again */}
            {showFullDescription && (
              <StyledButton onClick={() => setShowFullDescription(false)}>
                Show less
              </StyledButton>
            )}
          </SpecialSection>

            {/* Map Section */}
            <MapSection>
              <MapContainer
                center={[property.latitude, property.longitude]}
                zoom={10}
                scrollWheelZoom={false}
              >
                <TileLayer
                  url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibW9laW5rIiwiYSI6ImNtMXp1ZXQ0NDBieGYybHB3dWQ5NnUzd2EifQ.fI3xO0TzDrUjcOgGwkuVCQ"
                />
                <Marker
                  position={[property.latitude, property.longitude]}
                  icon={awesomeIcon}
                >
                </Marker>
              </MapContainer>
            </MapSection>
          </MainContent>

        <ContactFormContainer>
          {/* Contact Form */}
          <ContactForm>
            <h2>Contact the Agent</h2>

            {/* Agent Information */}
            {agentProfile && agentReview && (
              <AgentDetails onClick={(e) => handleAgentClick(property.agent_id, e)}>
                <div className="agent-photo">
                  <img src={agentProfile.photo} alt={agentProfile.preferred_name} />
                  <FaCheckCircle className="verified-icon" />
                </div>
                <div className="agent-info">
                  <span className="agent-name">{agentProfile.preferred_name}</span>
                  <span className="divider">|</span>
                  <span className="agent-rating">
                    {agentReview.overall_average_rating.toFixed(1)}
                  </span>
                  <span className="divider">|</span>
                  <span className="review-count">{agentReview.review_count} reviews</span>
                </div>
              </AgentDetails>
            )}

            <form onSubmit={handleSendMessage}>
              <label>Full Name</label>
              <input
                type="text"
                name="full_name"
                value={message.full_name}
                onChange={handleMessageChange}
                required
              />

              <label>Email or Phone</label>
              <input
                type="text"
                name="contact_info"
                value={message.contact_info}
                onChange={handleMessageChange}
                required
              />

              <label>Message</label>
              <textarea
                name="message"
                value={message.message}
                onChange={handleMessageChange}
                required
              />

              <button type="submit">Send Message</button>
            </form>
          </ContactForm>
        </ContactFormContainer>
      </MainContentWrapper>
    </PropertyDetailsContainer>
    </>
  );
};

export default PropertyDetailsPage;
