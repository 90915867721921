import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getPublicProperties,
  AgentProfile,
  PropertyDetail,
  getCountries,
  getCities,
  getHomeTypes,
  getHomeViews,
  Country,
  City,
  HomeType,
  HomeView,
  getAgentPublicProfile,
} from '../../services/propertyService';
import {
  PropertyResultsContainer,
  PropertiesGrid,
  PropertyCard,
  PropertyDetails,
  AgentDetails,
  FiltersSection,
  FilterForm,
  FilterGroup,
  FilterLabel,
  FilterDropdown,
  FilterItem,
  FilterSelect,
  FilterInput,
  FiltersToggleButton,
  MapWrapper,
  PropertiesSection
} from '../../styles/PropertyResultsStyles';
import FilterModal from '../../components/common/FilterModal';
import { TopAgent, getTopAgents, AgentReviewSummary, getAgentReviewSummary } from '../../services/agentService';
import PhotoCarousel from '../../components/property/PhotoCarousel';
import { FaCheckCircle, FaMapMarkerAlt } from 'react-icons/fa';
import UserTopBar from '../../components/common/UserTopBar';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L, { Marker as LeafletMarker, Map as LeafletMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import 'font-awesome/css/font-awesome.min.css';
import { GlobalMarkerStyles } from '../../styles/PropertyResultsStyles';


const INITIAL_CENTER: [number, number] = [35.2480, 33.6577];
const INITIAL_ZOOM = 9;


// Create a custom component to access the map instance
const MapInitializer: React.FC<{ mapRef: React.MutableRefObject<LeafletMap | null> }> = ({ mapRef }) => {
  const map = useMap();
  useEffect(() => {
    mapRef.current = map; // Store the map instance
  }, [map]);
  return null;
};

const PropertyResultsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();  // Add useNavigate hook
  const [properties, setProperties] = useState<PropertyDetail[]>([]);
  const [topAgents, setTopAgents] = useState<TopAgent[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [agentProfiles, setAgentProfiles] = useState<{ [key: number]: AgentProfile }>({});
  const [agentReviews, setAgentReviews] = useState<{ [key: number]: AgentReviewSummary }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showPriceFilter, setShowPriceFilter] = useState(false);
  const [showBedroomsFilter, setShowBedroomsFilter] = useState(false);
  const [showBathroomsFilter, setShowBathroomsFilter] = useState(false);
  const priceFilterRef = useRef<HTMLDivElement>(null);
  const bedroomsFilterRef = useRef<HTMLDivElement>(null);
  const bathroomsFilterRef = useRef<HTMLDivElement>(null);

  const [countries, setCountries] = useState<Country[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [homeTypes, setHomeTypes] = useState<HomeType[]>([]);
  const [homeViews, setHomeViews] = useState<HomeView[]>([]);
  const [cityLookup, setCityLookup] = useState<{ [key: number]: string }>({});
  const [hoveredProperty, setHoveredProperty] = useState<number | null>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  // Create a ref to store marker instances
  const mapRef = useRef<LeafletMap | null>(null);
  const markersRef = useRef<{ [key: number]: LeafletMarker<any> }>({});

  const createAwesomeMarker = (propertyId: number) => {
    const isHovered = hoveredProperty === propertyId;

    return L.AwesomeMarkers.icon({
      icon: 'home',
      prefix: 'fa',
      markerColor: 'red',
      iconColor: 'white',
      opacity: isHovered ? 1 : 0.6, // Set initial opacity based on hover state
    });
  };

  const params = new URLSearchParams(location.search);
  const initialFilters = {
    country: params.get('country') || '',
    city: params.get('city') || '',
    min_price: params.get('min_price') || '',
    max_price: params.get('max_price') || '',
    min_bedrooms: params.get('min_bedrooms') || '',
    max_bedrooms: params.get('max_price') || '',
    min_bathrooms: params.get('min_bathrooms') || '',
    max_bathrooms: params.get('max_price') || '',
    home_types: params.get('home_types') || '',
    home_views: params.get('home_views') || '',
    availability: params.get('availability') || '1',
  };
  const [filters, setFilters] = useState(initialFilters);

  // Get the selected country name
  const selectedCountry = countries.find(
    (country) => country.id.toString() === filters.country
  );
  const selectedCountryName = selectedCountry ? selectedCountry.name : 'All Countries';

  useEffect(() => {
    async function fetchProperties() {
      setIsLoading(true);
      try {
        const propertiesList = await getPublicProperties({ ...filters });
        setProperties(propertiesList);
        // Collect unique agent IDs
        const agentIds = Array.from(new Set(propertiesList.map((property) => property.agent_id)));

        // Fetch agent profiles and reviews
        const agentProfilesData: { [key: number]: AgentProfile } = {};
        const agentReviewsData: { [key: number]: AgentReviewSummary } = {};

        await Promise.all(
          agentIds.map(async (agentId) => {
            const [profileData, reviewData] = await Promise.all([
              getAgentPublicProfile(agentId),
              getAgentReviewSummary(agentId),
            ]);

            agentProfilesData[agentId] = profileData;
            agentReviewsData[agentId] = reviewData;
          })
        );

        setAgentProfiles(agentProfilesData);
        setAgentReviews(agentReviewsData);
      } catch (err) {
        setError('Failed to load properties.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }

    fetchProperties();
  }, [filters]);
  

  useEffect(() => {
    async function fetchTopAgentsWithScores() {
      try {
        const agentsList = await getTopAgents();
        const agentsWithDetails = await Promise.all(
          agentsList.map(async (agent) => {
            try {
              const [reviewSummary, agentProfile] = await Promise.all([
                getAgentReviewSummary(agent.id),
                getAgentPublicProfile(agent.id),
              ]);
              return {
                ...agent,
                overallScore: reviewSummary.overall_average_rating,
                review_count: reviewSummary.review_count,
                preferred_name: agentProfile.preferred_name,
                photo: agentProfile.photo,
              };
            } catch (error) {
              console.error(`Failed to fetch details for agent ${agent.id}:`, error);
              return {
                ...agent,
                overallScore: null,
                review_count: null,
                preferred_name: '',
                photo: '',
              };
            }
          })
        );
        setTopAgents(agentsWithDetails);
      } catch (err) {
        console.error('Failed to load top agents:', err);
        setError('Failed to load top agents.');
      }
    }
  
    fetchTopAgentsWithScores();
  }, []);


  useEffect(() => {
    async function fetchFilterData() {
      setIsLoading(true);
      try {
        const countriesList = await getCountries();
        const citiesList = await getCities();
        const homeTypesList = await getHomeTypes();
        const homeViewsList = await getHomeViews();

        // Create a city lookup map (id -> name)
        const cityMap: { [key: number]: string } = {};
        citiesList.forEach((city) => {
          cityMap[city.id] = city.name;
        });
        setCityLookup(cityMap);

        setCountries(countriesList);
        setCities(citiesList);
        setHomeTypes(homeTypesList);
        setHomeViews(homeViewsList);
      } catch (err) {
        setError('Failed to load filter data.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }

    fetchFilterData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Type assertion for TypeScript
      const target = event.target as Node;
  
      // Close Price Filter Dropdown
      if (
        showPriceFilter &&
        priceFilterRef.current &&
        !priceFilterRef.current.contains(target)
      ) {
        setShowPriceFilter(false);
      }
  
      // Close Bedrooms Filter Dropdown
      if (
        showBedroomsFilter &&
        bedroomsFilterRef.current &&
        !bedroomsFilterRef.current.contains(target)
      ) {
        setShowBedroomsFilter(false);
      }
  
      // Close Bathrooms Filter Dropdown
      if (
        showBathroomsFilter &&
        bathroomsFilterRef.current &&
        !bathroomsFilterRef.current.contains(target)
      ) {
        setShowBathroomsFilter(false);
      }
    };
  
    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPriceFilter, showBedroomsFilter, showBathroomsFilter]);  

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(updatedFilters);
  
    // Update the URL parameters
    const searchParams = new URLSearchParams();
  
    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key as keyof typeof updatedFilters]) {
        searchParams.append(key, updatedFilters[key as keyof typeof updatedFilters]);
      }
    });
  
    navigate(`/property-results?${searchParams.toString()}`, { replace: true });
  };  

  const handlePropertyClick = (propertyId: number) => {
    navigate(`/property-details/${propertyId}`);
  };

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Handle agent click to navigate to the agent's profile page
  const handleAgentClick = (agentId: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent card's click event
    navigate(`/agent-profile/${agentId}`);
  };

  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Toggle modal function
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePropertyHover = useCallback((propertyId: number | null) => {
    // Cancel any previous timeout if it exists
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    hoverTimeoutRef.current = setTimeout(() => {
      setHoveredProperty(propertyId);

      // Adjust marker opacity
      Object.values(markersRef.current).forEach((marker) => {
        marker.setOpacity(1); // Default opacity for all markers
      });

      if (propertyId && markersRef.current[propertyId]) {
        const marker = markersRef.current[propertyId];
        marker.setOpacity(1); // Highlight hovered marker

        const { lat, lng } = marker.getLatLng();
        mapRef.current?.setView([lat, lng], 12, { animate: true }); // Center on hovered marker
      } else {
        // Reset to initial view if no property is hovered
        mapRef.current?.setView(INITIAL_CENTER, INITIAL_ZOOM, { animate: true });
      }
    }, 100); // Delay to avoid flickering
  }, []);

  return (
    <>
    <UserTopBar />
    {isLoading && <LoadingSpinner />}
    <PropertyResultsContainer>

      <FiltersToggleButton onClick={toggleModal}>Apply Filters</FiltersToggleButton>
      {/* <TopAgentsSection>
        <TopAgentsHeading>Top Agents in {selectedCountryName}</TopAgentsHeading>
        <ScrollButtonLeft onClick={scrollLeft}>&lt;</ScrollButtonLeft>
        <AgentsContainer ref={agentsContainerRef}>
          {topAgents.length === 0 ? (
            <p>No top agents found.</p>
          ) : (
            topAgents.map((agent) => (
              <AgentCard key={agent.id} onClick={() => handleAgentClick(agent.id)}>
                <AgentPhoto>
                  <img src={agent.photo} alt={agent.preferred_name} />
                  <FaCheckCircle className="verified-icon" />
                </AgentPhoto>
                <TopAgentInfo>
                  <span className="agent-name">{agent.preferred_name}</span>
                </TopAgentInfo>
              </AgentCard>
            ))
          )}
        </AgentsContainer>
        <ScrollButtonRight onClick={scrollRight}>&gt;</ScrollButtonRight>
      </TopAgentsSection> */}

      <FiltersSection>
        <FilterForm>
          {/* Country Filter */}
          <FilterItem>
            <FilterSelect name="country" value={filters.country} onChange={handleFilterChange}>
              <option value="">Select a Country</option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </FilterSelect>
          </FilterItem>

          {/* City Filter */}
          <FilterItem>
            <FilterSelect name="city" value={filters.city} onChange={handleFilterChange}>
              <option value="">Select a City (Optional)</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </FilterSelect>
          </FilterItem>

          {/* Price Filter */}
          <FilterGroup ref={priceFilterRef}>
            <FilterLabel onClick={() => setShowPriceFilter(!showPriceFilter)}>
              Price
            </FilterLabel>
            {showPriceFilter && (
              <FilterDropdown>
                <FilterInput
                  type="number"
                  name="min_price"
                  value={filters.min_price}
                  onChange={handleFilterChange}
                  placeholder="Min Price"
                />
                <FilterInput
                  type="number"
                  name="max_price"
                  value={filters.max_price}
                  onChange={handleFilterChange}
                  placeholder="Max Price"
                />
              </FilterDropdown>
            )}
          </FilterGroup>

          {/* Bedrooms Filter */}
          <FilterGroup ref={bedroomsFilterRef}>
            <FilterLabel onClick={() => setShowBedroomsFilter(!showBedroomsFilter)}>
              Bedrooms
            </FilterLabel>
            {showBedroomsFilter && (
              <FilterDropdown>
                <FilterInput
                  type="number"
                  name="min_bedrooms"
                  value={filters.min_bedrooms}
                  onChange={handleFilterChange}
                  placeholder="Min Bedrooms"
                />
                <FilterInput
                  type="number"
                  name="max_bedrooms"
                  value={filters.max_bedrooms}
                  onChange={handleFilterChange}
                  placeholder="Max Bedrooms"
                />
              </FilterDropdown>
            )}
          </FilterGroup>

          {/* Bathrooms Filter */}
          <FilterGroup ref={bathroomsFilterRef}>
            <FilterLabel onClick={() => setShowBathroomsFilter(!showBathroomsFilter)}>
              Bathrooms
            </FilterLabel>
            {showBathroomsFilter && (
              <FilterDropdown>
                <FilterInput
                  type="number"
                  name="min_bathrooms"
                  value={filters.min_bathrooms}
                  onChange={handleFilterChange}
                  placeholder="Min Bathrooms"
                />
                <FilterInput
                  type="number"
                  name="max_bathrooms"
                  value={filters.max_bathrooms}
                  onChange={handleFilterChange}
                  placeholder="Max Bathrooms"
                />
              </FilterDropdown>
            )}
          </FilterGroup>

          {/* Home Type Filter */}
          <FilterItem>
            <FilterSelect name="home_types" value={filters.home_types} onChange={handleFilterChange}>
              <option value="">Select a Home Type</option>
              {homeTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </FilterSelect>
          </FilterItem>

          {/* Home View Filter */}
          <FilterItem>
            <FilterSelect name="home_views" value={filters.home_views} onChange={handleFilterChange}>
              <option value="">Select a Home View</option>
              {homeViews.map((view) => (
                <option key={view.id} value={view.id}>
                  {view.name}
                </option>
              ))}
            </FilterSelect>
          </FilterItem>
        </FilterForm>
      </FiltersSection>

      <PropertiesSection>
        {/* Properties List Section */}
        <div style={{ flex: 1 }}>
          {error && <p className="error">{error}</p>}
          {properties.length === 0 ? (
            <p>No properties found. Try adjusting your filters.</p>
          ) : (
            <>
              <p>{properties.length} {properties.length > 1 ? 'properties' : 'property'} listed on TimeAZ</p>
              <PropertiesGrid>
                {properties.map((property) => {
                  const agentProfile = agentProfiles[property.agent_id];
                  const agentReview = agentReviews[property.agent_id];

                  return (
                    <PropertyCard
                    key={property.id}
                    onMouseEnter={() => handlePropertyHover(property.id)} // Set hovered property
                    onMouseLeave={() => handlePropertyHover(null)} // Clear hovered property
                    onClick={() => handlePropertyClick(property.id)}
                    >
                      <PhotoCarousel photos={property.photos} />
                      <PropertyDetails>
                        <p className="property-info">
                          <strong className="property-price">{`${property.currency}${formatPrice(property.price)}`}</strong>{' '}
                          {property.bedrooms} beds | {property.bathrooms} baths | {property.area} sqm
                        </p>
                        <p><FaMapMarkerAlt /> {cityLookup[property.city]}</p>
                      </PropertyDetails>
                      {agentProfile && agentReview && (
                        <AgentDetails onClick={(e) => handleAgentClick(property.agent_id, e)}>
                          <div className="agent-photo">
                            <img src={agentProfile.photo} alt={agentProfile.preferred_name} />
                            <FaCheckCircle className="verified-icon" />
                          </div>
                          <div className="agent-info">
                            <span className="agent-name">{agentProfile.preferred_name}</span>
                            <span className="divider">|</span>
                            <span className="agent-rating">
                              {agentReview.overall_average_rating.toFixed(1)}
                            </span>
                            <span className="divider">|</span>
                            <span className="review-count">{agentReview.review_count} reviews</span>
                          </div>
                        </AgentDetails>
                      )}
                    </PropertyCard>
                  );
                })}
              </PropertiesGrid>
            </>
          )}
        </div>
      </PropertiesSection>

      <MapWrapper>
        {/* Map Section */}
        <GlobalMarkerStyles />
          <MapContainer center={INITIAL_CENTER} zoom={INITIAL_ZOOM} scrollWheelZoom={false} style={{ width: '100%', height: '100%' }}>
            <MapInitializer mapRef={mapRef} />
            <TileLayer
              url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibW9laW5rIiwiYSI6ImNtMXp1ZXQ0NDBieGYybHB3dWQ5NnUzd2EifQ.fI3xO0TzDrUjcOgGwkuVCQ"
            />
            {properties.map((property) => (
              <Marker
                key={property.id}
                position={[property.latitude, property.longitude]}
                icon={createAwesomeMarker(property.id)}
                ref={(ref) => {
                  if (ref) markersRef.current[property.id] = ref; // Store marker ref
                }}
                eventHandlers={{
                  mouseover: () => handlePropertyHover(property.id),
                  mouseout: () => handlePropertyHover(null),
                }}
              >
                <Popup>
                  <div>
                    <h4>{property.project_name}</h4>
                    <p>{`${property.currency}${formatPrice(property.price)}`}</p>
                    <button onClick={() => handlePropertyClick(property.id)}>View Details</button>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
      </MapWrapper>

      {/* Filters Modal */}
      <FilterModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        filters={filters}
        handleFilterChange={handleFilterChange}
        countries={countries}
        cities={cities}
        homeTypes={homeTypes}
        homeViews={homeViews}
      />
    </PropertyResultsContainer>
    </>
  );
};

export default PropertyResultsPage;