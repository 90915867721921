// src/styles/PropertyResultsStyles.ts
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

// Reuse styled components from HomePageStyles
import {
  PropertyDetails as HomePropertyDetails,
  AgentDetails as HomeAgentDetails,
} from './HomePageStyles';

// Main container for the property results page
export const PropertyResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr; /* Map 1/3, Properties 2/3 */
  grid-template-rows: auto 1fr; /* Filters on top, Scrollable content */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent body scroll */

  ${({ theme }) => theme.media.extraMedium`
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.small`
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.extraSmall`
    padding: ${theme.spacing.p1};
  `}
`;

export const PropertiesSection = styled.div`
  height: 100%; /* Full height */
  overflow-y: scroll; /* Enable vertical scrolling */
  padding: ${({ theme }) => theme.spacing.p2};

  /* Hide scrollbar for Chrome, Safari, and Edge */
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar width */
    height: 0; /* Remove horizontal scrollbar if present */
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE and older browsers */
  -ms-overflow-style: none;
`;


export const PropertiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 3fr)); /* Auto layout */
  gap: ${({ theme }) => theme.spacing.m3};
  padding: ${({ theme }) => theme.spacing.p2};
  
  ${({ theme }) => theme.media.small`
    grid-template-columns: 1fr; /* Single column on small screens */
  `}
`;

// Individual property card with responsive hover effect
export const PropertyCard = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: ${({ theme }) => theme.boxShadow};
  transition: ${({ theme }) => theme.transition};
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing.m0}; /* Remove default margin */
  position: relative;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  ${({ theme }) => theme.media.extraSmall`
    border-radius: ${theme.borderRadius.small}; /* Smaller border radius on small screens */
  `}
`;

// Reuse PropertyDetails
export const PropertyDetails = styled(HomePropertyDetails)``;

// Reuse AgentDetails
export const AgentDetails = styled(HomeAgentDetails)``;

// Update FiltersSection to prevent horizontal overflow
export const FiltersSection = styled.div`
  grid-column: 1 / -1; /* Span both columns (map and properties) */
  top: 50px;
  display: flex;
  position: sticky;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; // Allow the filter items to wrap properly
  gap: ${({ theme }) => theme.spacing.m2};
  margin-bottom: ${({ theme }) => theme.spacing.m3};
  max-width: 100%; /* Prevent overflow */
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.spacing.p3};
  padding-top: 50px; 
  margin-top: ${({ theme }) => theme.spacing.m4};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  box-sizing: border-box; /* Include padding and border in element’s total width and height */

  ${({ theme }) => theme.media.large`
    padding: ${theme.spacing.p3};
  `}

  ${({ theme }) => theme.media.extraMedium`
    display: none; /* Hide on medium screens */
  `}

  ${({ theme }) => theme.media.medium`
    display: none; /* Hide on medium screens */
  `}

  ${({ theme }) => theme.media.small`
    display: none; /* Hide on small screens */
  `}
`;

// Updated FilterGroup to ensure proper alignment
export const FilterGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column; // Stack elements vertically by default
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.m2};

  ${({ theme }) => theme.media.large`
    flex-direction: row; /* Arrange items in a row on large screens */
    align-items: center;
    gap: ${theme.spacing.m2};
  `}

  ${({ theme }) => theme.media.extraMedium`
    flex-direction: column; /* Stack filters vertically on small screens */
    align-items: flex-start;
  `}

  ${({ theme }) => theme.media.medium`
    flex-direction: column; /* Stack filters vertically on small screens */
    align-items: flex-start;
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Stack filters vertically on small screens */
    align-items: flex-start;
  `}

  ${({ theme }) => theme.media.extraSmall`
    flex-direction: column; /* Stack filters vertically on small screens */
    align-items: flex-start;
  `}
`;

// Filter label styling
export const FilterLabel = styled.label`
  padding: ${({ theme }) => `${theme.spacing.p2} ${theme.spacing.p3}`};
  height: 36px; /* Match the height of FilterSelect */
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.background};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
`;

// Ensure all Filter Groups are aligned and wrapped properly in the FiltersSection
export const FilterDropdown = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p2};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m1};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
`;

// Individual Filter Item Styling for consistent layout
export const FilterItem = styled.div`
  margin-right: ${({ theme }) => theme.spacing.m2};

  ${({ theme }) => theme.media.large`
    margin-right: ${theme.spacing.m2}; /* Spacing on large screens */
    flex: 1; /* Allow items to grow/shrink to fit available space */
  `}
`;

// Updated FilterSelect to manage width on large screens
export const FilterSelect = styled.select`
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing.p2} ${theme.spacing.p3}`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  background-color: ${({ theme }) => theme.colors.background};
  width: auto; /* Default width */
  box-sizing: border-box;

  ${({ theme }) => theme.media.large`
    width: auto; /* Adjust to fit content on large screens */
  `}
`;

// Filter input field styling
export const FilterInput = styled.input`
  padding: ${({ theme }) => `${theme.spacing.p1} ${theme.spacing.p2}`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  width: 100%;
`;

// Update FilterForm styling to manage width and alignment properly on large screens
export const FilterForm = styled.form`
  display: flex;
  flex-wrap: wrap; // Ensure filters wrap if there's not enough space
  gap: ${({ theme }) => theme.spacing.m2};
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally
  width: 100%; /* Full width */
  box-sizing: border-box;
  max-width: 1200px; /* Limit the width of the filter form */

  ${({ theme }) => theme.media.large`
    justify-content: space-between; // Distribute items evenly on large screens
    padding: ${theme.spacing.p2};
  `}

  ${({ theme }) => theme.media.extraMedium`
    justify-content: center;
    flex-wrap: wrap; /* Stack filters vertically if necessary */
  `}

  ${({ theme }) => theme.media.medium`
    justify-content: center;
    flex-wrap: wrap; /* Stack filters vertically if necessary */
  `}

  ${({ theme }) => theme.media.small`
    justify-content: center;
    flex-wrap: wrap; /* Stack filters vertically */
  `}
`;

export const FiltersToggleButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.p2};
  cursor: pointer;
  text-align: center;
  margin: ${({ theme }) => theme.spacing.m2} 0;
  display: none; /* Hidden by default */
  font-size: ${({ theme }) => theme.typography.small.fontSize};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
  }

  ${({ theme }) => theme.media.extraMedium`
    display: block; /* Show on medium screens */
    max-width: 15%;
  `}

  ${({ theme }) => theme.media.medium`
    display: block; /* Show on medium screens */
    max-width: 15%;
  `}

  ${({ theme }) => theme.media.small`
    display: block; /* Show on small screens */
    max-width: 30%;
  `}
`;

// Modal Container
export const FiltersModal = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */

  .modal-content {
    background-color: ${({ theme }) => theme.colors.background};
    padding: ${({ theme }) => theme.spacing.p4};
    border-radius: ${({ theme }) => theme.borderRadius.large};
    width: 90%;
    max-width: 500px; /* Default maximum width for modal */
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: ${({ theme }) => theme.boxShadow};
    position: relative;

    ${({ theme }) => theme.media.extraMedium`
      width: 50%; /* Adjust modal width for medium screens */
      max-width: 700px; /* Increase maximum width for better spacing */
    `}

    ${({ theme }) => theme.media.medium`
      width: 50%; /* Adjust modal width for medium screens */
      max-width: 700px; /* Increase maximum width for better spacing */
    `}

    ${({ theme }) => theme.media.small`
      width: 90%; /* Adjust modal width for medium screens */
      max-width: 700px; /* Increase maximum width for better spacing */
    `}

    ${({ theme }) => theme.media.extraSmall`
      width: 90%; /* Adjust modal width for medium screens */
      max-width: 700px; /* Increase maximum width for better spacing */
    `}
  }

  .close-button {
    position: absolute;
    top: ${({ theme }) => theme.spacing.p2};
    right: ${({ theme }) => theme.spacing.p2};
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

// Modal Filter Label styling for consistency
export const ModalFilterLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: flex-start; /* Align text to the left */
  justify-content: flex-start; /* Left align content */
`;

// Modal Filter Group styling for consistent spacing
export const ModalFilterGroup = styled.div`
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  align-items: flex-start; /* Align items to the left */
  gap: ${({ theme }) => theme.spacing.m2}; /* Vertical space between elements */

  ${({ theme }) => theme.media.extraMedium`
    flex-direction: column; /* Ensure vertical stacking for medium screens */
    gap: ${theme.spacing.m2}; /* Consistent spacing */
    width: 100%; /* Take full width */
  `}

  ${({ theme }) => theme.media.medium`
    flex-direction: column; /* Ensure vertical stacking for medium screens */
    gap: ${theme.spacing.m2}; /* Consistent spacing */
    width: 100%; /* Take full width */
  `}

  ${({ theme }) => theme.media.small`
    flex-direction: column; /* Ensure vertical stacking for medium screens */
    gap: ${theme.spacing.m1}; /* Consistent spacing */
    width: 100%; /* Take full width */
  `}
`;

// Filter input field styling to ensure full width
export const ModalFilterInput = styled.input`
  padding: ${({ theme }) => `${theme.spacing.p1} ${theme.spacing.p2}`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: ${({ theme }) => theme.typography.small.fontSize};
  width: 50%; /* Show side-by-side on small screens */

  ${({ theme }) => theme.media.extraMedium`
    width: 50%; /* Full width on medium screens */
  `}

  ${({ theme }) => theme.media.medium`
    width: 50%; /* Full width on medium screens */
  `}

  ${({ theme }) => theme.media.extraSmall`
    width: 50%; /* Full width on medium screens */
  `}
`;

// Filter select dropdown styling to ensure full width
export const ModalFilterSelect = styled.select`
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing.p2} ${theme.spacing.p3}`};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  background-color: ${({ theme }) => theme.colors.background};
  width: 45%; /* Show side-by-side on small screens */

  ${({ theme }) => theme.media.extraMedium`
    width: 100%; /* Full width on medium screens */
  `}

  ${({ theme }) => theme.media.medium`
    width: 100%; /* Full width on medium screens */
  `}
`;

// Ensure Filter Group's display is correctly handled on large screens
export const ModalFilterFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.m2};
  width: 100%;
  flex-wrap: nowrap;

  ${({ theme }) => theme.media.extraMedium`
    flex-direction: row; /* Stack elements vertically on medium screens */
    align-items: center;
  `}

  ${({ theme }) => theme.media.medium`
    flex-direction: row; /* Stack elements vertically on medium screens */
    align-items: center;
  `}
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%; /* Full height of the viewport */
  overflow: hidden; /* Prevent scrolling */
  box-shadow: ${({ theme }) => theme.boxShadow};
  
  .leaflet-container {
    height: 100%;
    width: 100%;
  }

  ${({ theme }) => theme.media.small`
    height: 300px; /* Adjust height on smaller screens */
  `}
`;

export const GlobalMarkerStyles = createGlobalStyle`
  .leaflet-marker-icon {
    opacity: 0.1; /* Default opacity */
    transition: opacity 0.3s ease-in-out; /* Smooth transition on hover */
  }

  .leaflet-marker-icon.marker-hovered {
    opacity: 1 !important; /* Full opacity when hovered */
  }
`;
