import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getCountries,
  getCities,
  getRankedProperties,
  getAgentPublicProfile,
  Country,
  City,
  PropertyDetail,
  AgentProfile,
} from '../../services/propertyService';
import ContactSection from '../../components/common/ContactSection';
import UserTopBar from '../../components/common/UserTopBar';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { getAgentReviewSummary, AgentReviewSummary } from '../../services/agentService';
import CustomDropdown from '../../components/common/CustomDropdown';
import PhotoCarousel from '../../components/property/PhotoCarousel';
import { FaCheckCircle, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import {
  HomePageContainer,
  SearchSection,
  SearchButton,
  PropertiesGrid,
  PropertyCard,
  PropertyDetails,
  AgentDetails,
  RecommendedHeading,
  BlogSection,
  BlogHeading,
  BlogPostsGrid,
} from '../../styles/HomePageStyles';
import { getBlogPosts, BlogPost } from '../../services/blogService';
import BlogPostCard from '../../components/common/BlogPostCard';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<Country[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [selectedCity, setSelectedCity] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [rankedProperties, setRankedProperties] = useState<PropertyDetail[]>([]);
  const [cityLookup, setCityLookup] = useState<{ [key: number]: string }>({});
  const [agentProfiles, setAgentProfiles] = useState<{ [key: number]: AgentProfile }>({});
  const [agentReviews, setAgentReviews] = useState<{ [key: number]: AgentReviewSummary }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recentPosts, setRecentPosts] = useState<BlogPost[]>([]);
  
  // Fetch countries on mount
  useEffect(() => {
    async function fetchCountries() {
      setIsLoading(true);
      try {
        const countriesList = await getCountries();
        setCountries(countriesList);
      } catch (err) {
        setError('Failed to load countries.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchCities() {
      try {
        setIsLoading(true);
        const citiesList = await getCities();
  
        // Create a city lookup map (id -> name)
        const cityMap: { [key: number]: string } = {};
        citiesList.forEach(city => {
          cityMap[city.id] = city.name;
        });
        setCityLookup(cityMap); 
  
        setCities(citiesList);
      } catch (err) {
        setError('Failed to load cities.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }
  
    fetchCities();
  }, []);  

  // Fetch ranked properties and agent details
  useEffect(() => {
    const fetchRankedProperties = async () => {
      try {
        setIsLoading(true);
        const data = await getRankedProperties();
        const topProperties = data.slice(0, 4); // Only take the top 4 properties
        setRankedProperties(topProperties);

        // Collect unique agent IDs
        const agentIds = Array.from(new Set(topProperties.map((property) => property.agent_id)));

        // Fetch agent profiles and reviews
        const agentProfilesData: { [key: number]: AgentProfile } = {};
        const agentReviewsData: { [key: number]: AgentReviewSummary } = {};

        await Promise.all(
          agentIds.map(async (agentId) => {
            const [profileData, reviewData] = await Promise.all([
              getAgentPublicProfile(agentId),
              getAgentReviewSummary(agentId),
            ]);

            agentProfilesData[agentId] = profileData;
            agentReviewsData[agentId] = reviewData;
          })
        );

        setAgentProfiles(agentProfilesData);
        setAgentReviews(agentReviewsData);
      } catch (err) {
        setError('Failed to load ranked properties or agent profiles.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    };

    fetchRankedProperties();
  }, []);

  // Fetch recent blog posts on mount
  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const posts = await getBlogPosts();
        setRecentPosts(posts.slice(0, 3)); // Take the latest 3 posts
      } catch (error) {
        console.error('Failed to load blog posts:', error);
      }
    };
    fetchRecentPosts();
  }, []);


  const handleCountryChange = (event: { target: { value: number } }) => {
    setSelectedCountry(event.target.value);
    setSelectedCity(null); // Reset city when country changes
  };

  const handleCityChange = (event: { target: { value: number } }) => {
    setSelectedCity(event.target.value);
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams();
    if (selectedCountry) queryParams.append('country', selectedCountry.toString());
    if (selectedCity) queryParams.append('city', selectedCity.toString()); // Only append city if it's selected

    navigate(`/property-results?${queryParams.toString()}`);
  };

  // Handle property click to navigate to the property detail page
  const handlePropertyClick = (propertyId: number) => {
    navigate(`/property-details/${propertyId}`);
  };

  // Handle agent click to navigate to the agent's profile page
  const handleAgentClick = (agentId: number, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent card's click event
    navigate(`/agent-profile/${agentId}`);
  };

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(
      number
    );
  };

  return (
    <>
    <UserTopBar />
    {isLoading && <LoadingSpinner />}
    <HomePageContainer>
      <SearchSection>
        <h1>Find Your Property</h1>
        {error && <p className="error">{error}</p>}

        {/* Custom Dropdowns for Country and City */}
        <div className="filter-section">
          <CustomDropdown
            placeholder="Select Country"
            name=""
            options={countries}
            value={selectedCountry}
            onChange={handleCountryChange}
          />

          <CustomDropdown
            placeholder="Select City (optional)"
            name=""
            options={cities}
            value={selectedCity}
            onChange={handleCityChange}
          />
          <SearchButton
            onClick={handleSearch}
            disabled={!selectedCountry}
            aria-label="Search"
          >
            <FaSearch />
          </SearchButton>
        </div>
      </SearchSection>

      <RecommendedHeading>Recommended for you</RecommendedHeading>

      {/* Ranked Properties Section */}
      <PropertiesGrid>
        {rankedProperties.map((property) => {
          const agentProfile = agentProfiles[property.agent_id];
          const agentReview = agentReviews[property.agent_id];

          return (
            <PropertyCard key={property.id} onClick={() => handlePropertyClick(property.id)}>
              {/* Property Carousel */}
              <PhotoCarousel photos={property.photos} />

              {/* Property Information */}
              <PropertyDetails>
                <p className="property-info">
                  <strong className="property-price">{`${property.currency}${formatPrice(
                    property.price
                  )}`}</strong>{' '}
                  {property.bedrooms} beds | {property.bathrooms} baths | {property.area} sqm
                </p>
                {/* <p>{property.project_name} project</p> */}
                <p>
                  <FaMapMarkerAlt /> {cityLookup[property.city]}
                </p>
              </PropertyDetails>

              {/* Agent Information */}
              {agentProfile && agentReview && (
                <AgentDetails onClick={(e) => handleAgentClick(property.agent_id, e)}>
                  <div className="agent-photo">
                    <img src={agentProfile.photo} alt={agentProfile.preferred_name} />
                    <FaCheckCircle className="verified-icon" />
                  </div>
                  <div className="agent-info">
                    <span className="agent-name">{agentProfile.preferred_name}</span>
                    <span className="divider">|</span>
                    <span className="agent-rating">
                      {agentReview.overall_average_rating.toFixed(1)}
                    </span>
                    <span className="divider">|</span>
                    <span className="review-count">{agentReview.review_count} reviews</span>
                  </div>
                </AgentDetails>
              )}
            </PropertyCard>
          );
        })}
      </PropertiesGrid>

      <BlogSection>
        <BlogHeading>Latest Blog Posts</BlogHeading>
        <BlogPostsGrid>
          {recentPosts.map((post) => (
            <BlogPostCard key={post.id} post={post} />
          ))}
        </BlogPostsGrid>
      </BlogSection>


      {/* Contact Section at the bottom */}
      <ContactSection />
    </HomePageContainer>
    </>
  );
};

export default HomePage;
