import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAgentProperties,
  changePropertyAvailability,
  updateAgentProperty,
  getCities,
  getHomeViews,
  getPropertyStatuses,
  ListedProperty,
  City,
  Country,
  HomeView,
  PropertyStatus,
  getCountries,
  CreateProperty
} from '../../services/propertyService';
import { getAgentReviewSummary, getAgentProfile, AgentProfile, AgentReviewSummary } from '../../services/agentService';
import { getBuyers, Buyer } from '../../services/userService';
import { FaPen, FaCheckCircle, FaMapMarkerAlt, FaTrash, FaCopy } from 'react-icons/fa';
import PhotoCarousel from '../../components/property/PhotoCarousel';
import { ListedPropertiesContainer, ActionButtonsContainer, PropertiesGrid, PropertyCard,
  PropertyDetails, AgentDetails, DeleteButton, EditButton, SoldButton, StyledModalForm, CancelButton,
  SaveButton, SaleModalContainer, SaleModalTitle, SaleModalLabel, SaleModalInput, SaleModalSelect,
  SaleModalCheckboxContainer, SaleModalButtonGroup, ConfirmButton, SaleModalRow,
  SaleModalColumn, SaleModalInputWithButton, IconButton, SaleModalSubtitle, ListedPropertiesHeading
 } from '../../styles/ListedPropertiesStyles';
import Topbar from '../../components/common/Topbar';
import { Breadcrumb } from '../../styles/PropertyCreationStepOneStyles';
import { deleteAgentProperties } from '../../services/propertyService';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const ListedPropertiesTab: React.FC = () => {
  const [agentprofile, setagentprofile] = useState<AgentProfile | null>(null);
  const baseUrl = window.location.origin;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const signUpUrl = `${baseUrl}/signup`; 
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deletingPropertyId, setDeletingPropertyId] = useState<number | null>(null);
  const [agentreviewSummary, setagentreviewSummary] = useState<AgentReviewSummary | null>(null);
  const [properties, setProperties] = useState<ListedProperty[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [editingProperty, setEditingProperty] = useState<ListedProperty | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [homeViews, setHomeViews] = useState<HomeView[]>([]);
  const [buyers, setBuyers] = useState<Buyer[]>([]);  // State for buyers
  const [saleDetails, setSaleDetails] = useState({ sold_to: '', sold_date: '', unverified_sold: false });
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<number | null>(null);
  const [propertyStatuses, setPropertyStatuses] = useState<PropertyStatus[]>([]);
  const [cityLookup, setCityLookup] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchAgentProfileAndReviews = async () => {
      setIsLoading(true);
      try {
        const profileData = await getAgentProfile();
        setagentprofile(profileData);

        if (profileData?.id) {
          const reviewData = await getAgentReviewSummary(profileData.id);
          setagentreviewSummary(reviewData);
        }
      } catch (err) {
        console.error('Failed to load agent profile or reviews:', err);
        setError('Failed to load agent profile or reviews.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    };

    fetchAgentProfileAndReviews();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const allProperties = await getAgentProperties();
        // Filter only available properties (assuming availability 1 means available)
        const availableProperties = allProperties.filter(property => property.availability === 1);
        setProperties(availableProperties);

        const fetchedCities = await getCities();
        const fetchedCountries = await getCountries();
        const fetchedHomeViews = await getHomeViews();
        const fetchedStatuses = await getPropertyStatuses();
  
        // Create a city lookup map (id -> name)
        const cityMap: { [key: number]: string } = {};
        fetchedCities.forEach(city => {
          cityMap[city.id] = city.name;
        });
        setCityLookup(cityMap);
  
        setCities(fetchedCities);
        setCountries(fetchedCountries);
        setHomeViews(fetchedHomeViews);
        setPropertyStatuses(fetchedStatuses);

      } catch (err) {
        setError('Failed to load data.');
      } finally {
        setIsLoading(false);  // End loading when fetching is done
      }
    }

    fetchData();
  }, []);

  const handleChangeAvailability = async (id: number, availability: number) => {
    if (availability === 2) {
      // If marking as sold, open the modal
      setSelectedPropertyId(id);
      setShowSaleModal(true);
    } else {
      try {
        const updatedProperty = await changePropertyAvailability(id, availability);
        setProperties(properties.map(property => 
          property.id === id 
          ? { ...property, availability: updatedProperty.availability } 
          : property
        ));
      } catch (err) {
        setError('Failed to update property availability.');
      }
    }
  };

  const handleSaleSubmit = async () => {
    if (selectedPropertyId !== null) {
      try {
        const updatedProperty = await changePropertyAvailability(selectedPropertyId, 2, saleDetails);
        setProperties(properties.map(property => 
          property.id === selectedPropertyId 
          ? updatedProperty 
          : property
        ));
        setShowSaleModal(false);
        setSelectedPropertyId(null);
      } catch (err) {
        setError('Failed to mark property as sold.');
      }
    }
  };

  const handleSaleDetailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSaleDetails({ ...saleDetails, [name]: value });
  };

  const handleUnverifiedSoldChange = () => {
    setSaleDetails({ ...saleDetails, unverified_sold: !saleDetails.unverified_sold });
  };

  // Edit Property Logic
  const handleEdit = (property: ListedProperty) => {
    setEditingProperty(property);
    setShowEditModal(true); // Open modal for editing
  };

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editingProperty) {
      try {
        // Convert the editingProperty object to match CreateProperty structure
        const submissionData: CreateProperty = {
          property_type: editingProperty.property_type,
          bedrooms: editingProperty.bedrooms,
          bathrooms: editingProperty.bathrooms,
          area: editingProperty.area,
          floor: editingProperty.floor,
          view: editingProperty.view,
          city: editingProperty.city,
          country: editingProperty.country,
          project_name: editingProperty.project_name,
          status: editingProperty.status,
          availability: editingProperty.availability,
          delivery_date: editingProperty.delivery_date || undefined,
          built_in: editingProperty.built_in || undefined,
          description: editingProperty.description,
          price: editingProperty.price,
          currency: editingProperty.currency,
          amenities: editingProperty.amenities || [],
          photo_data: editingProperty.photos,
          latitude: editingProperty.latitude || 0,  // Replace with actual values or remove if not needed
          longitude: editingProperty.longitude || 0, // Replace with actual values or remove if not needed
        };
  
        const updatedProperty = await updateAgentProperty(editingProperty.id, submissionData);
        setProperties(properties.map(property => (property.id === editingProperty.id ? updatedProperty : property)));
        setEditingProperty(null);
        setShowEditModal(false); // Close modal after editing
        toast.success('Property updated successfully', {
          position: 'top-center',
          autoClose: 3000,
        });
      } catch (err) {
        setError('Failed to update property.');
        toast.error('Failed to update the property.');
        setShowEditModal(false);
      }
    }
  };  

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (editingProperty) {
      const { name, value } = e.target;
      setEditingProperty({ ...editingProperty, [name]: value });
    }
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (editingProperty) {
      const selectedStatus = parseInt(e.target.value);
      const statusDetails = propertyStatuses.find(status => status.id === selectedStatus);

      setEditingProperty({
        ...editingProperty,
        status: selectedStatus,
        delivery_date: statusDetails?.requires_delivery_date ? editingProperty.delivery_date : '',
        built_in: statusDetails?.requires_built_in_date ? editingProperty.built_in : '',
      });
    }
  };

  useEffect(() => {
    async function fetchBuyers() {
      try {
        const buyersList = await getBuyers();
        setBuyers(buyersList);
      } catch (err) {
        setError('Failed to load buyers.');
      }
    }

    if (showSaleModal) {
      fetchBuyers();
    }
  }, [showSaleModal]);

  const formatPrice = (price: string) => {
    const number = parseFloat(price);
    return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 }).format(number);
  };

  const handleDeleteProperty = async () => {
    if (deletingPropertyId) {
      try {
        await deleteAgentProperties(deletingPropertyId);
        setProperties(properties.filter((property) => property.id !== deletingPropertyId));
        setShowDeleteModal(false);
        toast.success('Property deleted successfully', {
          position: 'top-center',
          autoClose: 3000, // Closes after 3 seconds
        });
      } catch (error) {
        console.error('Failed to delete the property:', error);
        toast.error('Failed to delete the property. Please try again.');
        setShowDeleteModal(false);
      }
    }
  };

  const handleDeleteClick = (propertyId: number) => {
    setDeletingPropertyId(propertyId); // Set the property to delete
    setShowDeleteModal(true); // Show the confirmation modal
  };  

  return (
    <>
    {isLoading && <LoadingSpinner />}
    <Topbar />
    <Breadcrumb>
      <a href="/agent-dashboard">Account</a>
      <span>›</span> {/* Unicode arrow symbol */}
      <span>Listed properties</span>
    </Breadcrumb>
    <ListedPropertiesContainer>
      <ListedPropertiesHeading>You have {properties.length} properties for sale on TimeAZ!</ListedPropertiesHeading>
      <PropertiesGrid>
        {properties.map((property) => (
          <div key={property.id} style={{ position: 'relative' }}>
            <PropertyCard key={property.id}>

              {/* Property Carousel */}
              <PhotoCarousel photos={property.photos} />

              {/* Property Information */}
              <PropertyDetails>
                <p className="property-info"> <strong className="property-price">{`${property.currency}${formatPrice(property.price)}`}</strong> {property.bedrooms} beds | {property.bathrooms} baths | {property.area} sqm</p>
                <p>{property.project_name} project</p>
                <p><FaMapMarkerAlt /> {cityLookup[property.city]}</p>
              </PropertyDetails>

              {/* Agent Information */}
              <AgentDetails>
                <div className="agent-photo">
                  <img src={agentprofile?.photo} alt={agentprofile?.preferred_name} />
                  <FaCheckCircle className="verified-icon" />
                </div>
                <div className="agent-info">
                  <span className="agent-name">{agentprofile?.preferred_name}</span>
                  <span className="divider">|</span>
                  <span className="agent-rating">{agentreviewSummary?.overall_average_rating.toFixed(1)}</span>
                  <span className="divider">|</span>
                  <span className="review-count">{agentreviewSummary?.review_count} reviews</span>
                </div>
              </AgentDetails>
            </PropertyCard>

            {/* Action Buttons - Outside the Property Card */}
            <ActionButtonsContainer>
              <EditButton onClick={() => handleEdit(property)}>
                <FaPen />
              </EditButton>
              <SoldButton onClick={() => handleChangeAvailability(property.id, property.availability === 1 ? 2 : 1)}>
                <FaCheckCircle />
              </SoldButton>
              <DeleteButton onClick={() => handleDeleteClick(property.id)}>
                <FaTrash />
              </DeleteButton>
            </ActionButtonsContainer>
          </div>
        ))}
      </PropertiesGrid>
    </ListedPropertiesContainer>
      {/* Confirmation Modal */}
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} size="xs">
        <Modal.Header>
          <Modal.Title>Delete Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this property? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDeleteProperty} color="red">
            Delete
          </Button>
          <Button onClick={() => setShowDeleteModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Property Modal */}
      {editingProperty && (
        <Modal open={showEditModal} onClose={() => setShowEditModal(false)} size="md">
          <StyledModalForm>
            <h2>Edit Property</h2>
            <form onSubmit={handleEditSubmit}>
              <label>Project Name</label>
              <input type="text" name="project_name" value={editingProperty.project_name} onChange={handleEditChange} />

              <label>Price</label>
              <input type="text" name="price" value={editingProperty.price} onChange={handleEditChange} />

              <label>Bedrooms</label>
              <input type="number" name="bedrooms" value={editingProperty.bedrooms} onChange={handleEditChange} />

              <label>Bathrooms</label>
              <input type="number" name="bathrooms" value={editingProperty.bathrooms} onChange={handleEditChange} />

              <label>Area (sqm)</label>
              <input type="number" name="area" value={editingProperty.area} onChange={handleEditChange} />

              <label>View</label>
              <select name="view" value={editingProperty.view} onChange={handleEditChange}>
                {homeViews.map(view => (
                  <option key={view.id} value={view.id}>
                    {view.name}
                  </option>
                ))}
              </select>

              <label>Status</label>
              <select
                name="status"
                value={editingProperty.status}
                onChange={handleStatusChange}
              >
                {propertyStatuses.map(status => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>

              {propertyStatuses.find(status => status.id === editingProperty.status)?.requires_delivery_date && (
                <div>
                  <label>Delivery Date</label>
                  <input
                    type="text"
                    name="delivery_date"
                    value={editingProperty.delivery_date || ''}
                    onChange={handleEditChange}
                  />
                </div>
              )}

              {propertyStatuses.find(status => status.id === editingProperty.status)?.requires_built_in_date && (
                <div>
                  <label>Built-in Date</label>
                  <input
                    type="text"
                    name="built_in"
                    value={editingProperty.built_in || ''}
                    onChange={handleEditChange}
                  />
                </div>
              )}

              <label>Description</label>
              <textarea name="description" value={editingProperty.description} onChange={handleEditChange} />

              <div className="button-group">
                <SaveButton type="submit">Save Changes</SaveButton>
                <CancelButton type="button" onClick={() => setShowEditModal(false)}>Cancel</CancelButton>
              </div>
            </form>
          </StyledModalForm>
        </Modal>
      )}

      {showSaleModal && (
        <Modal open={showSaleModal} onClose={() => setShowSaleModal(false)} size="md">
          <SaleModalContainer>
            <SaleModalTitle>Mark Property as Sold</SaleModalTitle>
            <form onSubmit={handleSaleSubmit}>
              {/* Existing form fields */}
              <SaleModalLabel>Sold To</SaleModalLabel>
              <SaleModalSelect
                name="sold_to"
                value={saleDetails.sold_to}
                onChange={handleSaleDetailChange}
                disabled={saleDetails.unverified_sold}
              >
                <option value="">Select Buyer</option>
                {buyers.map(buyer => (
                  <option key={buyer.id} value={buyer.id}>
                    {buyer.first_name} {buyer.last_name} ({buyer.email})
                  </option>
                ))}
              </SaleModalSelect>

              {/* New section for sharing sign-up link */}
              <SaleModalSubtitle>Your client is not on TimeAZ?</SaleModalSubtitle>

              <SaleModalRow>
                <SaleModalColumn>
                  <QRCodeCanvas value={signUpUrl} size={128} />  {/* QR Code for the signup link */}
                </SaleModalColumn>
                <SaleModalColumn>
                  <SaleModalInputWithButton>
                    <SaleModalInput type="text" readOnly value={signUpUrl} />
                    <IconButton type="button" onClick={() => navigator.clipboard.writeText(signUpUrl)}>
                      <FaCopy />
                    </IconButton>
                  </SaleModalInputWithButton>
                </SaleModalColumn>
              </SaleModalRow>


              <SaleModalLabel>Sold Date (MM/YYYY)</SaleModalLabel>
              <SaleModalInput
                type="text"
                name="sold_date"
                value={saleDetails.sold_date}
                onChange={handleSaleDetailChange}
                placeholder="MM/YYYY"
              />

              <SaleModalCheckboxContainer>
                <input
                  type="checkbox"
                  name="unverified_sold"
                  checked={saleDetails.unverified_sold}
                  onChange={handleUnverifiedSoldChange}
                />
                <SaleModalLabel>Mark as Unverified Sold</SaleModalLabel>
              </SaleModalCheckboxContainer>

              <SaleModalButtonGroup>
                <ConfirmButton type="submit">Confirm Sale</ConfirmButton>
                <CancelButton type="button" onClick={() => setShowSaleModal(false)}>Cancel</CancelButton>
              </SaleModalButtonGroup>
            </form>
          </SaleModalContainer>
        </Modal>
      )}
    </>
  );
};

export default ListedPropertiesTab;
