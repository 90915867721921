// src/components/common/CustomGoogleButton.tsx
import React, { useState, CSSProperties } from 'react';
import { FcGoogle } from 'react-icons/fc'; // Import Google icon from react-icons

interface CustomGoogleButtonProps {
  onClick: () => void; // Callback function for the button click
}

const CustomGoogleButton: React.FC<CustomGoogleButtonProps> = ({ onClick }) => {
  // State for handling hover effect
  const [isHovered, setIsHovered] = useState(false);

  // Handlers for hover effect
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Button styles with conditional hover styling
  const customButtonStyles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // Stretch to full width
    padding: '12px 16px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: isHovered ? '#f7f7f7' : '#ffffff', // Background color changes on hover
    border: isHovered ? '1px solid #ccc' : '1px solid #ddd', // Border color changes on hover
    color: '#444', // Text color
    cursor: 'pointer',
    boxShadow: isHovered ? '0 4px 6px rgba(0, 0, 0, 0.15)' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Shadow depth changes on hover
    transition: 'background-color 0.3s, border-color 0.3s, box-shadow 0.3s', // Smooth transition
    outline: 'none', // Remove default outline
    marginTop: '20px', // Add margin-top to create space above the button
  };

  return (
    <button
      onClick={onClick}
      style={customButtonStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FcGoogle style={{ marginRight: '10px' }} size={24} /> {/* Google logo */}
      <span style={textStyles}>Sign in with Google</span>
    </button>
  );
};

// Text styles
const textStyles: CSSProperties = {
  fontWeight: 500,
  fontSize: '16px',
};

export default CustomGoogleButton;
