// src/services/userService.ts
import api from './api';

// Interface for login request
interface LoginData {
  username: string;
  password: string;
}

// Interface for signup request
interface SignupData {
  username: string;
  password: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

// Interface for token response
interface TokenResponse {
  access: string;
  refresh: string;
}

// Interface for token refresh request
interface TokenRefreshData {
  refresh: string;
}

export interface Buyer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface UserProfile {
  id: number;
  first_name: string;
  last_name: string;
}

// Function to get user profile
export const getUserProfile = async (): Promise<UserProfile> => {
  const token = getAccessToken();
  if (!token) {
    throw new Error('No access token available');
  }
  const response = await api.get('/users/profile/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data as UserProfile;
};

// Fetch list of buyers
export const getBuyers = async (): Promise<Buyer[]> => {
  const token = localStorage.getItem('access_token');  
  const response = await api.get(`/users/buyers/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Login function to obtain access and refresh tokens
export const login = async (data: LoginData): Promise<TokenResponse> => {
  const response = await api.post('/users/login/', data);
  const tokens: TokenResponse = response.data;

  // Store the tokens in localStorage (or a more secure storage solution)
  localStorage.setItem('access_token', tokens.access);
  localStorage.setItem('refresh_token', tokens.refresh);

  return tokens;
};

export const storeTokens = (access: string, refresh: string) => {
  localStorage.setItem('access_token', access);
  localStorage.setItem('refresh_token', refresh);
};

// Function to logout the user
export const logout = async (): Promise<void> => {
  // Get tokens from localStorage (or wherever you are storing them)
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  if (!accessToken || !refreshToken) {
    throw new Error('No tokens available for logout');
  }

  try {
    // Make the API request to logout
    await api.post(
      '/users/logout/',
      { refresh: refreshToken }, // Send the refresh token in the body
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Send the access token in the Authorization header
        },
      }
    );

    // Clear tokens from localStorage after a successful logout
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  } catch (error) {
    console.error('Logout error:', error);
    throw error; // Re-throw the error if you want to handle it further up
  }
};


// Signup function to register a new user
export const signup = async (data: SignupData): Promise<void> => {
  await api.post('/users/signup/', data);
};

// Function to refresh the access token using the refresh token
export const refreshAccessToken = async (data: TokenRefreshData): Promise<TokenResponse> => {
  const response = await api.post('/users/token/refresh/', data);
  const newTokens: TokenResponse = response.data;

  // Update the tokens in localStorage
  localStorage.setItem('access_token', newTokens.access);
  localStorage.setItem('refresh_token', newTokens.refresh);

  return newTokens;
};

// Helper function to get the current access token
export const getAccessToken = (): string | null => {
  return localStorage.getItem('access_token');
};

// Helper function to get the current refresh token
export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refresh_token');
};
